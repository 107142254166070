import * as React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'
import { linkResolver } from '../utils/linkResolver'
import Layout from '../components/Layout'
import styled from 'styled-components'

const Loading = styled.div`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.variable};
  font-size: ${props => props.theme.fontSizes[4]}px;
  padding: ${props => props.theme.space[11]}px 0;
  text-align: center;
`

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return (
    <Layout>
      <Loading>Naughty naughty! You're not supposed to be here.</Loading>
    </Layout>
  )

  return (
    <Layout>
      <Loading>Loading your preview.</Loading>
    </Layout>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'ronikdesign',
  accessToken: 'MC5YUlBwY1JBQUFDSUFvVWdo.77-977-9PO-_vS7vv73vv71S77-9X--_vWnvv70O77-9GO-_vVY_77-9GBLvv73vv73vv73vv73vv73vv71SVwRv',
  pathResolver: linkResolver,
})
